import mainRefs from "@/__main__/refs.mjs";
import { appURLs, GAME_ACTIVE_MAP } from "@/app/constants.mjs";
import adsRefs from "@/feature-ads/refs.mjs";
import { HeaderWrapper } from "@/feature-reskin-probuilds-net/HeaderWrapper.jsx";
import reskinRefs from "@/feature-reskin-probuilds-net/refs.mjs";
import { CHAMPIONS } from "@/game-lol/constants/champion-classes.mjs";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition-symbol.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import footerRefs from "@/marketing/footer.refs.mjs";
import { catchAllRoutes } from "@/routes/constants.mjs";
import { homepageRoutes } from "@/routes/marketing.mjs";
import routes from "@/routes/routes.mjs";
import appRouteRefs from "@/util/app-route.refs.mjs";
import deepEqual from "@/util/deep-equal.mjs";
import lazyFn from "@/util/lazy-fn.mjs";
import mapOriginalRefs, {
  forceOverwriteIterable,
} from "@/util/map-original-refs.mjs";
import pathRegExp from "@/util/path-regexp.mjs";

const original = mapOriginalRefs({
  mainRefComponents: mainRefs.components,
  appRouteRefs,
  mainRefs,
  routes,
  footerRefs,
  lolRefs,
  reskinRefs,
  appURLs,
  adsRefs,
});

const fetchStaticData = lazyFn(
  () => import("@/game-lol/fetches/lol-fetch-static-data.mjs"),
);
const fetchHomeData = lazyFn(
  () => import("@/feature-reskin-probuilds-net/fetches/fetch-home-data.mjs"),
);
const fetchGuides = lazyFn(
  () => import("@/feature-reskin-probuilds-net/fetches/fetch-guides.mjs"),
);
const fetchArticle = lazyFn(
  () => import("@/shared/fetch-contentful-article.mjs"),
);

function findRouteIndex(match) {
  return routes.findIndex((route) => {
    return deepEqual(match, route);
  });
}

const memoizedActiveGames = {};
let unsubscribe;

const probuildsRoutes = [
  // Probuilds Contentful
  {
    path: "/",
    component: "feature-reskin-probuilds-net/Home.tsx",
    fetchData: fetchHomeData,
  },
  {
    path: "/guides",
    component: "feature-reskin-probuilds-net/Guides.tsx",
    fetchData: fetchGuides,
  },
  {
    path: pathRegExp("/article/:id"),
    component: "feature-reskin-probuilds-net/ProBuildsArticle.tsx",
    fetchData: fetchArticle,
  },
  // Probuilds Redirects
  {
    path: "/champions",
    redirect: `${appURLs.BLITZ}/lol/champions/overview`,
    // component: "feature-reskin-probuilds-net/Champions.tsx",
    // fetchData: fetchChampionsData,
  },
  {
    path: "/champions/overview",
    redirect: `${appURLs.BLITZ}/lol/champions/overview`,
    // component: "feature-reskin-probuilds-net/Champions.tsx",
    // fetchData: fetchChampionsData,
  },
  {
    path: pathRegExp("/champion/:championId/abilities{/}?"),
    redirect: ({ parameters }) => {
      const [championId] = parameters;
      const champion = CHAMPIONS?.[championId]?.key || championId;
      return `${appURLs.BLITZ}/lol/champions/${champion}/build`;
    },
  },
  {
    path: pathRegExp("/champions/details/:championId{/:role}?"),
    redirect: ({ parameters }) => {
      const [championId, role] = parameters;
      const champion = CHAMPIONS?.[championId]?.key || championId;
      return `${appURLs.BLITZ}/lol/champions/${champion}/build${role ? `?role=${role}` : ""}`;
    },
    // component: "feature-reskin-probuilds-net/Champion.tsx",
    // fetchData: fetchChampionData,
  },
  {
    path: pathRegExp("/role/:role"),
    redirect: ({ parameters }) => {
      const [role] = parameters;
      return `${appURLs.BLITZ}/lol/probuilds/history?role=${role}`;
    },
    // component: "feature-reskin-probuilds-net/Role.tsx",
    // fetchData: fetchRoleData,
  },
  {
    path: "/pros",
    redirect: `${appURLs.BLITZ}/lol/tierlist`,
    // component: "feature-reskin-probuilds-net/Pros.tsx",
    // fetchData: () => {},
  },
  {
    path: pathRegExp("/pros/details/:proId"),
    redirect: `${appURLs.BLITZ}/lol/probuilds/history`,
    // component: "feature-reskin-probuilds-net/Pro.tsx",
    // fetchData: fetchProData,
  },
  {
    path: "/teams",
    redirect: appURLs.BLITZ,
    // component: "feature-reskin-probuilds-net/Teams.tsx",
    // fetchData: () => {},
  },
  {
    path: pathRegExp("/teams/details/:teamId"),
    redirect: appURLs.BLITZ,
    // component: "feature-reskin-probuilds-net/Team.tsx",
    // fetchData: fetchTeamData,
  },
  {
    path: pathRegExp("/guide/show/:region/:matchId/:puuid"),
    redirect: `${appURLs.BLITZ}/lol/probuilds/history`,
    // component: "feature-reskin-probuilds-net/Guide.tsx",
    // fetchData: fetchGuideData,
  },
];
const [homeRoute, ...otherRoutes] = probuildsRoutes;

async function fetchProbuildsData() {
  await Promise.all([fetchStaticData()]);
}

const catchAll = {
  [catchAllRoutes]: probuildsRoutes,
  fetchData: fetchProbuildsData,
};

export function setup() {
  const homepageIndex = findRouteIndex(homepageRoutes[0]);

  const siteName = ["probuildsnet:name", "ProBuilds.net"];
  siteName[forceOverwriteIterable] = true;

  original.set({
    mainRefs: {
      DEFAULT_META_IMAGE: {
        url: `${appURLs.CDN_PLAIN}/probuildsnet/og-image.png`,
        alt: ["probuildsnet:probuildsnetLogo", "ProBuilds.net Logo"],
        width: 640,
        height: 430,
      },
      DEFAULT_SITE: siteName,
    },
    reskinRefs: {
      activeGameSet: new Set(
        Object.getOwnPropertySymbols(GAME_ACTIVE_MAP).filter(
          (symbol) => GAME_ACTIVE_MAP[symbol],
        ),
      ),
    },
    lolRefs: {
      lolChampionsPrefix: "champions",
      lolChampionPrefix: "champion",
      lolTierlistPrefix: "tierlist",
    },
    footerRefs: {
      Logo: null,
    },
    mainRefComponents: {
      HeaderWrapper,
    },
    appRouteRefs: {
      pageHeaderOverride: true,
    },
    routes: {
      [homepageIndex]: homeRoute,
    },
  });

  routes.push(catchAll, ...otherRoutes);

  for (const symbol of Object.getOwnPropertySymbols(GAME_ACTIVE_MAP)) {
    memoizedActiveGames[symbol] = GAME_ACTIVE_MAP[symbol];
    if (symbol === GAME_SYMBOL_LOL) continue;
    GAME_ACTIVE_MAP[symbol] = false;
  }
}

export function teardown() {
  unsubscribe?.();
  original.restore();

  for (const route of [catchAll, ...otherRoutes]) {
    routes.splice(routes.indexOf(route), 1);
  }

  for (const symbol of Object.getOwnPropertySymbols(GAME_ACTIVE_MAP)) {
    GAME_ACTIVE_MAP[symbol] = memoizedActiveGames[symbol];
  }
}
